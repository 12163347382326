<template>
    <div class="my-visitor">
        <div class="list">
            <template v-if="list.length > 0">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="到底啦〜想不到你看到了这里^_^"
                    @load="getGoodsVisitor"
                >
                    <share-goods-item v-for="(item, index) in list" :key="index" :item="item"></share-goods-item>
                </van-list>
            </template>
            <no-data v-else content="这里没有数据"></no-data>
        </div>
    </div>
</template>
<script>
import { getGoodsVisitor } from "@/http/api";
import login from "@/common/islogin";
import ShareGoodsItem from "./shareGoodsChildren/ShareGoodsItem";
export default {
    components: {
        ShareGoodsItem
    },
    data() {
        return {
            list: [],
            finished: true,
            loading: false,
            pages: 1,
            userInfo: {},
            userToken: ""
        };
    },
    methods: {
        // 我的访客列表
        async getGoodsVisitor() {
            const res = await getGoodsVisitor({
                data: {
                    user_token: this.userToken,
                    pages: this.pages
                }
            });
            this.loading = false;
            if (res.data.length > 0) {
                this.list = this.list.concat(res.data);
                this.pages++;
                this.finished = true;
            } else {
                this.finished = false;
            }
        }
    },
    mounted() {
        //  执行判断登录
        let curUrl = this.$route.path;
        if ((this.userInfo = login.isLogin(curUrl))) {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            // this.userInfo = this.$LStorage.getItem("user_info");
            this.getGoodsVisitor();
        }
    },
    filters: {},
    created() {}
};
</script>
<style lang="scss" scoped>
.my-visitor {
    background: #f6f6f6;
    height: 100vh;
    overflow-y: auto;
}
</style>