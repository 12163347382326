<template>
  <div class="my-visitor-grid">
    <div class="goods-info">
      <img :src="item.goods_info.cover_imgurl" >
      <div class="desc">
        <p class="goods-name">{{item.goods_info.goods_name}}</p>
        <p class="goods-price" v-if="false">价格：￥{{item.goods_info.sale_price}}   预计：￥{{ commission }}</p>
      </div>
    </div>
    <div class="grid-bot">
      <div class="grid-bot-item">
        <p>点击次数</p>
        <span>{{item.visit_goods_total}}</span>
      </div>
      <div class="xian"></div>
      <router-link :to="'/my/goods/visitor?share_id=' + item.share_id + '&goods_id=' + item.goods_id" class="grid-bot-item">
        <p>浏览人数</p>
        <a>{{item.visit_user_total}}</a>
      </router-link>
      <div class="xian"></div>
      <div class="grid-bot-item">
        <p>成交订单</p>
        <span>{{item.visit_order_total}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {}
  },
  computed: {
    commission() {
      if(this.item){
        var commission1 = Number(this.item.goods_info.commission1),
          commission2 = Number(this.item.goods_info.commission2)
        return commission1/100 + '-' + (commission1 + commission2)/100
      }
    }
  },
  watch: {},
  created () { },
  mounted () { },
  methods: {}
}
</script>

<style scoped lang="scss">
.my-visitor-grid {
  background: #fff;
  padding: 3vw 3vw 0;
  margin-top: 3vw;
  .goods-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3vw;
    img {
      width: 18.6vw;
      height: 14.6vw;
      border-radius: 5px;
    }
    .desc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 14.6vw;
    }
    .goods-name {
      width: 72vw;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 0.26rem;
    }
    .goods-price {
      color: #ff5400;
      font-size: 0.22rem;
    }
  }
  .grid-bot {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid #ebebeb;
    .xian {
      width: 1px;
      height: 30px;
      background: #ebebeb;
    }
    .grid-bot-item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 60px;
      p {
        font-size: 0.24rem;
        color: #7c7c7c;
      }
      span {
        font-size: 0.3rem;
        color: #000;
      }
      a {
        font-size: 0.3rem;
        color: #ff5400;
        text-decoration: underline;
      }
    }
  }
}
</style>
